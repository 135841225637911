.register-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-control.valid {
	border-color: #66a593;
}

.form-control.invalid {
	border-color: red;
}

.btn-signup {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: white;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: darkolivegreen;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.4rem;
}

.btn-signup:disabled {
    background-color: #cccccc;
}

.btn-to-login{
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: white;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: darkred;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.4rem;
}

.form_group_recaptcha > div > div {
    display: flex;
    place-content: center;
}
